body {
  margin: 0;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Questrial", sans-serif;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
  background-color: var(--main-bg-color);
  cursor: url('../public/files/cursor.png'), auto;
}

:root {
  --main-bg-color: #000000;
  --color-primary : #ffffff;
  --color-white : #ffffff;

}
