#skills {
    background-color: var(--color-white);
}

.skills-container {
    display: grid;
    grid-template-columns: 40% 60%;
    padding-top: 4rem;
}

.skills-heading {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skills-heading p:nth-child(1) {
    font-weight: bolder;
    font-size: 2.5rem;
}
.skills-heading p:nth-child(2) {
    font-size: 2.5rem;
    color: #bcbcbc;
}

#skills.active .skills-heading {
    position: fixed;
    top: 2rem;
    left: 10.5rem;
}

#skills.active .skills-content {
    grid-column: 2 / span 1;
  }

.skills-heading p {
    width: 100%;
    max-width: 300px;
    margin: 0;
}

.skills-content {
    padding-right: 6rem;
}

.skills-section-card:first-child {
    margin: 0;
}

.skills-section-card {
    border-left: 1.5px solid #000; 
    border-color: #bcbcbc;;
    padding-left: 2rem; 
    margin: 5rem 0;
  }

  .skills-section-title {
    font-size: 3rem;
    font-weight: 500;
    transition: color 0.5s ease;
  }

  .skills-section-title:hover {
    color: rgba(93,235,215,1);
  }

  .skills-section-skills {
    font-size: 1.2rem;
    letter-spacing:0.05rem;
  }

  .skills-section-description {
    font-size: 1.1rem;
    letter-spacing:0.05rem;
  }




  @media screen and (max-width: 1200px) {
    .skills-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding-top: 4rem;
    } 

    #skills.active .skills-heading {
      position: relative;
      top: auto;
      left: auto;
    }

    .skills-content {
      padding-right: 0;
  }
  .skills-section-card:first-child,
  .skills-section-card {
    border-left: 1.5px solid #000; 
    border-color: #bcbcbc;;
    padding-left: 1rem; 
    margin: 5rem 2rem;
    width: auto;
  }

  .skills-section-title {
    margin: 1rem 0;
  }
}

@media screen and (max-width: 1200px) {
  .trivia-game {
    padding: 10px;
  }

  .card {
    width: 95%; /* Increase card width to utilize more screen space */
    max-width: 90%; /* Adjust max-width for smaller screens */
  }

  .points {
    font-size: 1.3rem; /* Slightly smaller font size for points */
  }

  .question-number {
    font-size: 1rem; /* Adjust font size for question number */
  }

  .question {
    font-size: 1.3rem; /* Slightly smaller font size for the question */
  }

  .option-button {
    font-size: 0.9rem; /* Slightly smaller font size for options */
    padding: 8px 16px; /* Adjust padding for smaller screens */
  }

  .game-over p {
    font-size: 1.2rem; /* Smaller font size for the game over message */
  }

  .disclaimer {
    font-size: 0.8rem; /* Smaller font size for disclaimer */
  }
}