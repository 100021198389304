

.about-section {
    display: grid;
    padding: 133.3px 85.3px;
    align-items: center;
    gap: 114.6px;
    grid-template-columns: repeat(2, 1fr);
    color: white;
    position: relative;
  }
  
  .about-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: radial-gradient(circle, #ffffff 10%, transparent 11%);
    background-size: 2em 2em;
    background-color: #000000;
    opacity: 0.2;
    z-index: -1;
  }


  .about-section-img > img {
    width: 70%;
    height: 70%;
    border-radius: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
    position: relative;
    left: 6rem;
  }
  
  
  .about-section-img > img:hover {
    transform: scale(1.05); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
  }

  .about-section-content {
    width: 80%;
  }

  .about-section-title {
    font-size: large;
    color: #EEEDEB;
  }

  .about-section--heading {
    font-size: 2rem;
  }

  .about-section-description {
    font-size: 1.1rem;
    letter-spacing:0.13rem;
    line-height: 2.5ch;
  }



  @media screen and (max-width: 1200px) {
    .about-section {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      padding: 0;
      align-items: center;
      margin-top: 2rem;
    }   
  
    .about-section-img > img {
      width: 300px; 
      height: 300px; 
      object-fit: cover; 
      position: static; 
      margin: 0 auto; 
      margin-top: 3rem;
    }

    .about-section-title,
    .about-section--heading {
      text-align: center;
    }
  
  
  }