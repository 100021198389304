/* Navbar section start */

#navbar {
    position: relative;
    display: flex;
    justify-content:space-around;
    align-items: center;
    padding: 0;
    margin: 0 auto;
    background-color: var(--main-bg-color);
  }

  
  .nav-links {
    display: flex;
    align-items: center;
    padding-right: 20px; 
    height: 8rem;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.2);
  }

  .nav-logo {
    position: fixed;
    top: 2rem;
    left: 1rem;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  
  .navlogo {
    height: 4rem;
    width: 6rem;
    transition: transform 0.5s ease-in-out; 
  }

  .navlogo.active-black {
    filter: brightness(0) saturate(100%)
  }
  
  .navlogo:hover {
    transform: scale(0.8);
  }
  
  .nav-link-container {
    display: flex;
    gap: 1.5rem;
    list-style: none;
  }
  
  
  .nav-items-container {
    display: flex;
  }
  
  
  .nav-link-items {
    color: var(--color-primary);
    font-weight: bold;
    position: relative;
    cursor: pointer;
}

  .nav-link-items:hover {
    color: rgba(255,255,255, 0.6);;
  }

.nav-link-items::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 3px;
    background-image: linear-gradient(90deg, rgba(197,255,149,1) 0%, rgba(93,235,215,1) 25%, rgba(22,121,171,1) 56%, rgba(7,65,115,1) 83%);
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.nav-link-items:hover::after {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}
  
  
  .social-links {
    display: flex;
    height: 100%;
  
  }
  
  .social-link-container {
    display: flex;
    gap: 1.5rem;
    list-style: none;
  }
  
  .social-link-items {
    color: var(--color-primary);
    opacity: 1;
    transition: opacity 1s ease 0s;
  }

  .social-link-items:hover {
    opacity: 0.7;
  }
  
  
  
  @media screen and (max-width: 1200px) {
    #navbar{
      justify-content: space-between;
      width: 100%;
      margin-bottom: 2rem;
    }
  
    .nav-logo {
      z-index: 2;
      position: relative;
      top: 1rem;
      left: 1rem;
      padding: 0;
      margin: 0;
      display: flex;
    }
    .hamburger-menu {
      position: absolute;
      top: 25px;
      right: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 20px;
      height: 20px;
      z-index: 2;
    }
    .nav-line {
      display: block;
      background-color: var(--color-primary);
      width: 100%;
      height: 0.188rem;
      border-radius: 0.625rem;
      transition: all ease-in-out 0.2s;
    }
    .nav-links {
      background: var(--main-bg-color);
      background: linear-gradient(162deg, var(--bg-color-first-shade) 0%, var(--bg-color-second-shade) 88%);
      position: absolute;
      width: 100%;
      align-items: center;
      left: 0;
      top: -20rem;
      z-index: 1;
      transition: all ease-in-out 0.4s;
      box-sizing: border-box;
      padding-right: 0;
    }

    .social-links {
      display: none;
    }
  
    .nav-links ul {
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 3rem 0 0.6rem;
      z-index: -100;
    }
    .nav-links ul li {
      text-align: center;
    }
  
    .nav-items-container {
      flex-direction: column;
    }
    .nav-link-container {
      flex-direction: column;
    }

  
   }
  
   .nav-links.nav-active {
    top: 30px;
    height: 100vh;
    background-color: #000;
   }
  
   .hamburger-menu.nav-active :nth-child(1){
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
   }
   .hamburger-menu.nav-active :nth-child(2){
    opacity: 0;
   }
   .hamburger-menu.nav-active :nth-child(3){
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
   }
  
  /* Navbar section end */