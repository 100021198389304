.footer-container {
    display: flex;
    padding: 106.667px 85.333px;
    flex-direction: column;
    align-items: center;
    color: var(--color-white);
    position: relative; /* Ensure it's positioned relative to its container */
}

.footer-top-pattern {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10%; /* Covering the top 20% of the footer */
    background: radial-gradient(circle at top left, transparent 9%, #ffffff 10%, #ffffff 15%, transparent 16%),
                radial-gradient(circle at bottom left, transparent 9%, #ffffff 10%, #ffffff 15%, transparent 16%),
                radial-gradient(circle at top right, transparent 9%, #ffffff 10%, #ffffff 15%, transparent 16%),
                radial-gradient(circle at bottom right, transparent 9%, #ffffff 10%, #ffffff 15%, transparent 16%),
                radial-gradient(circle, transparent 25%, #000000 26%),
                linear-gradient(45deg, transparent 46%, #ffffff 47%, #ffffff 52%, transparent 53%),
                linear-gradient(135deg, transparent 46%, #ffffff 47%, #ffffff 52%, transparent 53%);
    background-size: 2em 2em;
    background-color: #000000;
    opacity: 0.15;
    z-index: -1; /* Place it behind other content */
}

.footer-link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    position: relative; /* Ensure it's above the pattern */
}

.footer-items > ul {
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 42.667px;
}

.footer-items ul > li > a {
    text-decoration: none;
    color: var(--color-primary);
    font-weight: bold;
    position: relative;
    cursor: pointer;
}

.footer-items ul > li > a:hover {
    color: rgba(255, 255, 255, 0.6);
}

.footer-items ul > li > a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 3px;
    background-image: linear-gradient(90deg, rgba(197, 255, 149, 1) 0%, rgba(93, 235, 215, 1) 25%, rgba(22, 121, 171, 1) 56%, rgba(7, 65, 115, 1) 83%);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}

.footer-items ul > li > a:hover::after {
    visibility: visible;
    transform: scaleX(1);
}

/* Apply hover color to all other items */
.button:hover,
.footer-social-icon a:hover,
.social-link-items:hover,
.footer-content:hover,
.footer-contact-details a:hover {
    color: rgba(93, 235, 215, 1);
}

.footer-social-icon > ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
}

.divider {
    margin: 106px 0 42.67px;
    height: 0.08px;
    width: 100%;
    background-color: #EEEDEB;
    border: none;
}

.footer-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.footer-content {
    color: var(--color-white);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
}

.footer-active-content {
    color: var(--primary);
}

.footer-contact-details a {
    text-decoration: none;
    color: var(--color-white);
    font-weight: bold;
    font-size: 1.05rem;
    letter-spacing: 0.05rem;
}


@media screen and (max-width: 1200px) {

    .footer-link-container,
    .footer-items > ul,
    /* .footer-social-icon > ul, */
    .footer-content-container {
        display: flex;
        flex-direction: column;
    }

    .footer-social-icon > ul,
    .footer-items > ul {
        padding: 0;
        align-items: center;
    }

    .footer-container {
        gap: 5px;
    }

    .divider {
        margin: 1rem 1rem;
    }

    .footer-contact-details {
        text-align: center;
    }
  }