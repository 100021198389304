.trivia-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  background-color: #000;
  color: #fff;
  font-family: 'Arial', sans-serif;
}

.trivia-heading {
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: "Acme", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.trivia-card {
  background-color: #1c1c1c;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  padding: 20px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.points {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.question-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question-number {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.question {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: left;
}

.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.option-button {
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  flex: 1 1 calc(50% - 20px); /* Adjust button width for two buttons per row */
}

.option-button:hover {
  background-color: rgba(93,235,215,1);
}

.option-button.correct {
  background-color: #28a745 !important;
}

.option-button.incorrect {
  background-color: #dc3545 !important;
}

.game-over {
  margin-top: 20px;
}

.game-over p {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

button {
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.retry-button {
  margin-top: 20px;
}

.disclaimer {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #aaa;
}

/* Media Query for screens up to 1200px */
@media screen and (max-width: 1200px) {
  .trivia-card {
    width: 85%; /* Decrease card width */
  }
}

/* Media Query for screens up to 768px */
@media screen and (max-width: 768px) {
  .trivia-card {
    width: 90%; /* Further decrease card width */
  }

  .points,
  .question-number,
  .question,
  .option-button {
    font-size: 1rem; /* Adjust font size */
  }

  .question {
    margin-bottom: 15px;
  }
}
