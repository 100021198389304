#contact {
  background: var(--main-bg-color);
  padding: 5rem 0;
}

.contact-container {
  background-color: var(--color-white);
  padding: 50px 50px;
  gap: 3rem;
  margin: 1rem auto;
  align-items: center;
  text-align: center;
  color: black;
  max-width: 80%;
}


.contact--section {
  display: flex;
  padding: 150px 85px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 64px;
}
.contact--form--container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  width: 40%;
  row-gap: 32px;
  padding-top: 50px;
  padding-left: 50px;
  margin: 0 auto;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 32px;
  row-gap: 32px;
}
.contact--label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  flex: 1 0 0;
  width: 100%;
  font-size: 14px;
}
.contact--input {
  display: flex;
  font-family: "Roboto";
  padding: 4px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid rgba(93,235,215,1);
  background: var(--color-white);
  font-size: 14px;
  margin-bottom: -10px;
}

.contact--input:focus {
  border-color: rgba(7,65,115,1); 
  box-shadow: 0 0 5px rgba(7,65,115,1); 
  outline: none; 
}

.contact-container > h1 {
  font-size: 2rem;
}

.contact-container > p {
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing:0.10rem;
}

.btn-contact {
  font-weight: normal;
  font-size: 1.1rem;
  color: var(--color-white);
  background-color: rgba(93,235,215,1);
  border: rgba(93,235,215,1);
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(93,235,215,1);
  gap: 16px;
}

.btn-contact:hover {
  background-color: rgba(7,65,115,1);
}
/* contact start */

  .text-md {
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  textarea {
    resize: none;
  }

  @media screen and (min-width: 1200px) and (max-width: 2560px) {
    .contact-container {
      max-width: 75%;
    }
  }

  @media screen and (max-width: 1200px) {
    #contact {
      padding: 1rem 0;
      padding-top: 5rem;
    }
  
    .contact-container {
      padding: 10px;
      max-width: 80%;
      margin: 0 auto; 
    }
  
    .contact--section {
      gap: 20px;
      padding: 70px 15px; 
      text-align: center; 
    }
  
    .contact--form--container {
      width: 100%;
      max-width: 600px; 
      padding: 2rem; 
      margin: 0 auto; 
      box-sizing: border-box; 
    }
  
    .container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    .contact-form-img-container {
      display: flex;
      flex-direction: column-reverse;
    }
  
    .contact--label,
    .contact--input {
      width: 100%; 
      box-sizing: border-box; 
    }
  
    .contact--input {
      padding: 10px; 
    }
  }


  /* Contact End */





