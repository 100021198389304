/* Main section starts */

.main-section {
    font-family: "Questrial", sans-serif;
    font-weight: 400;
    font-style: normal;
    display: flex;
    align-items: center;
    background: var(--main-bg-color);
    /* height: 80vh; */
    margin-top: 8rem;
  }

  .main-section-content {
    color: var(--color-white);
    padding: 0 0 2rem;
    position: relative;
    left: 15rem;
  }

  .main-section-content-heading {
    font-size: 48px;
    line-height: 4rem;
  }

  .heading-gradiant-color {
    background: linear-gradient(90deg, rgba(197,255,149,1) 0%, rgba(93,235,215,1) 25%, rgba(22,121,171,1) 56%, rgba(7,65,115,1) 83%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }

  .main-section-content-heading > * {
    margin: 0;
    animation: slideInLeftFade 1s ease-out;
  }

  .main-section-video {
    width: 65rem;
    height: 20%;
    border-radius: 20px;
    overflow: hidden; 
    margin-top: 4rem;
  }
  
  .main-video {
    width: 100%;
    height: 100%;
    border-radius: 20px; 
    opacity: 0.6; 
  }
  

  @keyframes slideInLeftFade {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .main-section-content-para {
    margin-top: 2rem;
    font-size: 25px;
    line-height: 2.5rem;
    color: #888888;
  }

  .main-section-content-para > * {
    margin: 0;
    animation: fadeIn 5s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  
  .para-char {
    display: inline-block; /* Ensure the span is an inline-block element */
    transition: transform 0.3s; /* Smoothly transition the transform property */
  }
  
  
  .para-char.up {
    transform: translateY(-5px); /* Move the character up */
    animation: none; /* Disable the animation */
  }

  @keyframes moveUp {
    0% { transform: translateY(0); }
    100% { transform: translateY(-5px); }
  }

  @media screen and (min-width: 1200px) and (max-width: 2560px) {
    .main-section-video {
      width: 55rem;
    }
  }
  

  @media screen and (max-width: 1200px) {

    .main-section {
      margin: 5rem 2rem;
    }

    .main-section-content {
      left: 0;
      padding: 0 0 2rem;
      position: relative;
      align-items: center;
    }

    .main-section-video {
      width: 20rem;
    }
  }

  /* Main section end */