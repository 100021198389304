#portfolio {
    margin-top: 3rem;
}

.portfolio-container {
    color: white;
    padding: 5rem;
    position: relative;
    left: 4rem;
}

.portfolio-title {
    font-size: 2.5rem;
}

.portfolio-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    padding: 3rem;
}

.portfolio-section-card {
    width: 30rem;
}

.portfolio-section-card.even-card {
    margin-top: 10rem;
}

.btn {
    display: inline-block;
    padding: 14px 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    transition: background-color 0.5s;
    -webkit-transition: background-color 0.5s;
    -moz-transition: background-color 0.5s;
    -ms-transition: background-color 0.5s;
    -o-transition: background-color 0.5s;
  }

  .btn-github {
    font-weight: normal;
    font-size: 1.1rem;
    color: var(--color-white);
    background-color: rgba(93,235,215,1);
    border: rgba(93,235,215,1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 2px 0 rgba(93,235,215,1);
    gap: 16px;
    position: relative;
    left: 2.5rem;
  }
  .btn-github:hover {
    color: rgba(93,235,215,1);
    background-color: var(--color-white);
  }

  .portfolio-video {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .portfolio-video video {
    width: 100%;
    height: 300px;
    object-fit: cover; 
    border-radius: 20px; 
    opacity: 0.9;
  }

  .portfolio-section-title {
    font-size: 1.8rem;
    letter-spacing:0.05rem;
  }

  .portfolio-section-description {
    font-size: 1.1rem;
    letter-spacing:0.05rem;
  }

  .github-link {
    text-decoration: none;
  }

  @media screen and (min-width: 1200px) and (max-width: 2560px) {
    .portfolio-container {
      left: 0;
    }
    .portfolio-title {
      position: relative;
      left: 2.5rem;
    }
  }


  @media screen and (max-width: 1200px) {

    .portfolio-cards-container {
      display: flex;
      flex-direction: column;
      gap: 4rem;
    }

    .portfolio-section-card.even-card {
      margin-top: 0;
    }
    .portfolio-container {
      position: relative;
      left: 0;
      padding: 10px;
    }

    .portfolio-section-card {
      width: auto;
    }
    .portfolio-title {
      text-align: center;
    }
  }